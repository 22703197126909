import figures_url from "@/views/home/img/bc.jpg";
import rightIcon from "@/views/home/img/rightIcon.png";
import keYan from "@/views/home/img/keyan.jpg";
import keYan1 from "@/views/home/img/keyan1.jpg";
import dingwei from "@/views/home/img/dingwei.png";
import rili from "@/views/home/img/rili.png";
import youxiang from "@/views/home/img/youxiang.png";
import news from "@/views/home/img/news.jpg";

import news1 from "@/views/home/img/news1.jpg";
import news2 from "@/views/home/img/news2.jpg";
import news3 from "@/views/home/img/news3.jpg";
import news4 from "@/views/home/img/news4.png";

import people1 from "@/views/home/img/people1.png";
import people2 from "@/views/home/img/people2.png";
import people3 from "@/views/home/img/people3.png";

import pic1 from "@/views/home/img/pic1.jpg";
import pic2 from "@/views/home/img/pic2.png";
import pic3 from "@/views/home/img/pic3.png";
import pic4 from "@/views/home/img/pic4.png";
import pic5 from "@/views/home/img/pic5.png";
import pic6 from "@/views/home/img/pic6.png";
import pic7 from "@/views/home/img/pic6.png";
import test1 from "@/assets/homePages/Testimonial/1.png";
import test2 from "@/assets/homePages/Testimonial/2.png";
import test3 from "@/assets/homePages/Testimonial/3.webp";
import test4 from "@/assets/homePages/Testimonial/4.png";
import test5 from "@/assets/homePages/Testimonial/5.png";
import test6 from "@/assets/homePages/Testimonial/6.webp";
import test7 from "@/assets/homePages/Testimonial/7.png";
import test8 from "@/assets/homePages/Testimonial/8.png";
import test9 from "@/assets/homePages/Testimonial/9.webp";
import neR from "@/views/home/img/neR.png";
import teR from "@/views/home/img/teR.png";

import noPictureEvent from "@/assets/homePages/Events/women.jpg";
import mockPicture2 from "@/assets/homePages/Events/women1.png";
import mockPicture3 from "@/assets/homePages/Events/student.jpg";
import mockPicture4 from "@/assets/homePages/Events/student1.jpg";

export const englishHomeInfo = {
  infoName: 'en',
  video: {
    text01: `BGI College`,
    text02: `Dedicated to Nurturing Top-Notch Innovative Talents in Life Sciences`,
  },
  Figures: {
    text01: `BGI College at a glance`,
    text02: `We are steadfast in facing the cutting-edge of gobal technology, targeting crucial economic frontines, addressing significant national demands, and prioritizing public health and well-being; we focus on practices and real-world applications as educational characteristics, establish an innovative platform complementary to current life sciences education and aim to cultivate top-notch innovative talents capable of leading the advancement of frontier life sciences and the development of strategic emerging industries.`,
    text03: `Figures`,
    figuresList: [
      { inx: "0", text: "Projects", num: "100+" },
      { inx: "1", text: "Advisors", num: "400+" },
      { inx: "2", text: "CNS", num: "500+" },
      { inx: "3", text: "SCI", num: "1000+" },
      { inx: "4", text: "Patents", num: "400+" },
      { inx: "5", text: "Graduates", num: "2000+" },
      { inx: "6", text: "Professional training", num: "20+" },
      { inx: "7", text: "Trainees", num: "7000+" }
    ],
    btnText: `Learn more  `,
  },

  UpcomingEvents: {
    text01: `Latest Events`,
    btnText: `Read More`,
  },
  testimonial: {
    text01: `Testimonial`,
    testimonialList: [
      [
        {
          p:
            "During the several months of co-cultivation project, I've achieved a lot and feel stronger and more confident than ever!",
          sp:
            "2023-2024 Guest student \n Undergraduate Alexandria University",
          h1: "Yang Aiyiwei",

          src: test1,
          manageId: 21
        },
        {
          p:
            "My work and study at BGI has exposed me to cutting-edge scientific technologies, greatly benefiting my future development.",
          sp: "2023 - 2024 IT DEL-BGI \n Undergraduate Innovative Class",
          h1: "Abdelrahman Amir",

          src: test2,
          manageId: 22
        },
        {
          p:
            "The joint project at BGI has deepened my understanding of genomics.I eagerly anticipate further collaboration with BGI to jointly drive scientific progress.",
          sp:
            "2023 - 2024 Northwest University-BGI \n Undergraduate Innovative Class",
          h1: "Yashuang Guo",

          src: test3,
          manageId: 16
        }
      ],
      [
        {
          p:
            "BGI is a vibrant and positive academic community! ",
          sp: "2021-2024 Master Student \n Zhengzhou University -BGI Joint training Program",
          h1: "Wyclif Ochieng Odago",
          manageId: 23,
          src: test4
        },
        {
          p:
            "My involvement in an international cooperation project to study human diseases through Stereo-seq has enhanced my professional skills.",
          sp:
            "2022 Master Student \n University of Chinese Academy of Sciences-BGI joint program",
          h1: "Zhouwei Xie",

          src: test5,
          manageId: 20
        },
        {
          p:
            "Throughout my study time at BGI, teamwork in such a harmonious atmosphere has enhanced my basic abilities in various aspects.",
          sp: "2023 - 2024 IT DEL-BGI \n Undergraduate Innovative Class",
          h1: "Audrey Mihewi",

          src: test6,
          manageId: 24
        }
      ],
      [
        {
          p:
            "People here in BGI is so nice and kind.They are willing to help me learn not just about the study, but also about how to live a life here. ",
          sp: "2024 University of Indonesia \n Undergraduate Innovative Class",
          h1: "Lintang Putri",

          src: test7,
          manageId: 25
        },
        {
          p:
            "It has been a huge blessing for me to join the project team at BGI as an international student. It has been a very memorable experience for me to work on challenging projects with supportive teammates, and it has inspired me to keep growing. This experience has broadened my perspective on the practical significance of the knowledge I study, in addition to giving me a deeper understanding of my discipline. I think this experience has been invaluable to my professional development and academic growth. My fortune lies not only in the academic side of learning, but also in the bonds formed with the other members of my team. Working with students from different countries and backgrounds has broadened my perspective and improved my social interactions. The excellent support I received from my excellent supervisor was also very helpful in facing the challenges of the project and ensuring that I was able to make the maximum contribution. Students who decide not to join this program will not have the opportunity to experience this.",
          sp: "2023 - 2024 IT DEL-BGI \n Undergraduate Innovative Class",
          h1: "Mentari Sihombing",
          manageId: 26,
          src: test8
        },
        {
          p:
            "During the entire time I am conducting my research, I have received professional training from my supervisors and built a solid relationships with my peers.",
          sp: "2021-2024 Master Student \n Zhengzhou University -BGI Joint training Program",
          h1: "Disheng Shi",

          src: test9,
          manageId: 19
        }
      ]
    ],
    btnText: `Learn More`,
  },
  news: {
    text01: `News`,
    newsList: [
      {
        h1: "BGI Launches Scholarship to Support Students and Researchers from Serbia",
        h2:
          "BGI College, in collaboration with the Mammoth Foundation, launched the BGI Serbia-China Scholarship to support students, scholars, and researchers from Serbia to engage in educational and academic activities at BGI ... ",
        date: "November 13, 2023",
        img: news1
      }
    ],
    btnText: `More News`,
  },
  subscribe: {
    
    btnText: `SUBSCRIBE NOW`,
  }
}

export const chineseHomeInfo = {
  infoName: 'cn',
  video: {
    text01: `华大教育中心`,
    text02: `致力于培养生命科学领域的创新型拔尖人才`,
  },
  Figures: {
    text01: `中心理念`,
    text02: `我们坚持面向世界科技前沿，面向经济主战场，面向国家重大需求，面向人民生命健康，倾力推动产教融合和科教交融，并与高等教育机构积极探索协同育人和创新机制的新途径；我们以实践、实战为教育特色，建立与当下生命科学教育互补的创新平台，培养能引领生命前沿科学进步和战略新兴产业发展的创新拔尖人才。`,
    text03: `数说中心`,
    figuresList: [
      { inx: "0", text: "项目", num: "100+" },
      { inx: "1", text: "导师", num: "400+" },
      { inx: "2", text: "CNS", num: "500+" },
      { inx: "3", text: "SCI", num: "1000+" },
      { inx: "4", text: "专利", num: "400+" },
      { inx: "5", text: "毕业生", num: "2000+" },
      { inx: "6", text: "职业培训", num: "20+" },
      { inx: "7", text: "培训学员", num: "7000+" }
    ],
    btnText: `了解更多`
  },
  UpcomingEvents: {
    text01: `近期活动`,
    btnText: `更多活动`,
  },
  testimonial: {
    text01: `感言`,
    testimonialList: [
      [
        {
          p:
            "在这几个月的项目中，我获得了丰富的知识和友谊，这让我更加强大和自信！",
          sp:
            "2023级东北林业大学-华大创新班",
          h1: "杨艾旖为",

          src: test1
        },
        {
          p:
            "在华大的学习令我难忘，我在华大万物的工作，让我接触到了最前沿的科学技术，这对我未来的发展有很大的帮助",
          sp: "2023级本科客座学生-埃及亚历山大大学",
          h1: "Abdelrahman Amir",

          src: test2
        },
        {
          p:
            "参与华大的联合培养项目是一次令人难以置信的经历，它拓宽了我的视野，加深了我对基因组学的理解。 与才华横溢的团队、尖端技术人才一起工作令人鼓舞。 协作氛围和多元化的观点推动了我们对科学卓越的追求。 感谢这个机会，我期待继续和华大合作，推动科学进步。",
          sp:
            "2023级西北大学-华大创新班",
          h1: "郭雅霜",

          src: test3
        }
      ],
      [
        {
          p:
            "华大是一个非常棒的的科学和创新的地方。 每个人都很好。 华大欢迎每个人，这里的的所有人都愿意为您的科学事业提供帮助，这是一个充满爱心的社区。",
          sp: "2024级博士联培学生-中国科学院大学",
          h1: "Wyclif Ochieng Odago",

          src: test4
        },
        {
          p:
            "华大拥有良好的研究环境，年度自然指数排行榜常年位居亚太地区第一。 期间，我参与了一个通过Stereo-seq研究人类疾病的国际合作项目，这对我专业能力的提升有很大帮助。",
          sp:
            "2022级硕士联培学生-中国科学院大学",
          h1: "谢宙维",

          src: test5
        },
        {
          p:
            "在华大的学习期间，我一直处于一种合作的氛围中，这对我的基本技能的提高很有帮助，并且我得到了导师和同事的鼓励和信心，这促进了我在团队中的成长。",
          sp: "2023级本科联培学生-印尼德尔理工大学",
          h1: "Audrey Mihewi",

          src: test6
        }
      ],
      [
        {
          p:
            "我觉得华大的人都很友善。 他们不仅愿意帮助我了解学习，还帮助我了解如何在这里生活。 所以我很高兴在这里看到有什么不同。 好的，欢迎加入华大联合培养项目。",
          sp: "2024级本科联培学生-印尼大学",
          h1: "Puan Haliza",

          src: test7
        },
        {
          p:
            "作为一名国际学生，能够加入华大的项目团队对我来说是一个巨大的祝福。 与支持我的队友一起完成具有挑战性的项目对我来说是一次非常难忘的经历，它激励我不断成长。 这次经历拓宽了我对所学知识的实际意义的视野，也让我对我的学科有了更深入的了解。 我认为这段经历对我的专业发展和学术成长非常宝贵。 我的幸运不仅在于学术方面的学习，还在于与团队其他成员形成的联系。 与来自不同国家和背景的学生一起工作拓宽了我的视野并改善了我的社交互动。 我优秀生物导师给予我的大力支持，也非常有助于我应对项目的挑战并确保我能够做出最大的贡献。 不参加该计划的学生很难有机会体验这一点。",
          sp: "2023级本科联培学生-印尼德尔理工大学",
          h1: "Mentari Sihombing",

          src: test8
        },
        {
          p:
            "华大拥有非常完善的研究环境。 在我进行研究的整个过程中，我接受了导师的专业和复杂的培训。 我还与同事建立了友好的联系。",
          sp: "2021级华大-郑州大学联合培养硕士项目",
          h1: "施迪燊",

          src: test9
        }
      ]
    ],
    btnText: `查看更多`,
  },
  news: {
    text01: `新闻公告`,
    newsList: [
      {
        h1: "BGI Launches Scholarship to Support Students and Researchers from Serbia",
        h2:
          "BGI College, in collaboration with the Mammoth Foundation, launched the BGI Serbia-China Scholarship to support students, scholars, and researchers from Serbia to engage in educational and academic activities at BGI ... ",
        date: "November 13, 2023",
        img: news1
      }
    ],
    btnText: `更多新闻`,
  },
  subscribe: {
    text01: `订阅关注`,
    btnText:`现在订阅`,
  },
}