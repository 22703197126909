export const commonEvt = {
  data() {
    return {
    }
  },
  methods: {
    scrollToTop() {
      if (this.$router.currentRoute.name == 'home'){
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动
        });
        this.$store.commit('changeNavIndex', localStorage.getItem('hIndex1'))
        return
      }else{
        window.scrollTo({
          top: 580,
          behavior: 'smooth' // 平滑滚动
        });
        this.$store.commit('changeNavIndex', localStorage.getItem('hIndex1'))
      }
    },
  }
}