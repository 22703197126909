<template>
  <a-config-provider :locale="locale">
    <div id="app">

      <Header></Header>
      <router-view />
      <Footer></Footer>

    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Header from './components/header/index.vue'
import Footer from './components/footer/index.vue'
import { englishLang } from "./minixs/langChangeMinix";
export default {
  name: 'App',
  components: {
    Header, Footer
  },
  mounted(){
    // 当组件挂载后获取宽高比
    this.getWidthHeightRatio();
    window.addEventListener('resize', this.getWidthHeightRatio);

    console.log(this.$route,'rtu');
    const hIndex = window.localStorage.getItem('hIndex')
    const filterItem = englishLang.state.navList.filter(item => item.inx == hIndex)[0]
    this.$router.push({ name: filterItem.name });
    // document.title= localStorage.getItem('changeLanguage')=='cn'?'华大教育中心':'BGI-College'
    // this.$router.push({ name: 'home' });
    // window.localStorage.setItem('hIndex', '-1')
    // window.localStorage.setItem('hIndex1', '1')

    window.addEventListener('popstate', () => {
      // 当发生回退时的处理逻辑
      // alert('页面正在回退！');
      const hIndex = window.localStorage.getItem('hIndex')
      const filterItem = englishLang.state.navList.filter(item => item.inx == hIndex)[0]
      console.log(filterItem, ',]===============);')
      this.$router.push({ name: filterItem.name, query: { id: localStorage.getItem('hIndex1') } });
      location.reload();
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 清理监听器，防止内存泄漏
    window.removeEventListener('resize', this.updateAspectRatio);
  },
  data() {
    return {
      locale: zhCN,
      scrollTop:0,
    }
  },
  methods:{
    handleScroll() {
      // 获取当前滚动的位置
      const supportPageOffset = window.scrollY !== undefined;
      const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
      this.scrollTop = supportPageOffset ? window.scrollY : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
      if(this.scrollTop < 580){
        document.querySelector('.mobileHeaderBox').style.backgroundColor = `rgba(255, 255, 255, 0)`
      }else{
        document.querySelector('.mobileHeaderBox').style.backgroundColor = `rgba(0, 0, 0, 0.6)`
      }
    },
    getWidthHeightRatio() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const ratio = width > 0 && height > 0 ? width / height : 0;
      // console.log(`当前页面宽高比为: ${ratio}`);
      document.documentElement.style.setProperty('--window-WidthHeightRatio', ratio); //将宽度传递给CSS变量
      // return ratio
    }
  }
}
</script>

<style>
@import url(./views/home/css/mobileStyle.less);
p {
  margin-bottom: 0px;
}
#app {
      font-family: Noto Serif SC;
      font-style: normal;
      overflow: hidden;
      /* font-weight: 400; */
    }
.event-nav-content {
  h1{
    flex: none;
  }
}

.header {
  overflow-x: clip;
}
</style>
