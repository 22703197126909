import Vue from "vue";
import Vuex from "vuex";
import { englishLang, chineseLang } from "@/minixs/langChangeMinix";

Vue.use(Vuex);
const store = new Vuex.Store({
  // 配置（state|mutations|actions）
  state: {
    hIndex: -1,
    navIndex: 0,
    language: "en",//cn、en
    navList: englishLang.state.navList
  },
  getters: {
    get(state) {
      return state.age;
    },
    getNavList(state) {
      return state.navList;
    }
  },
  mutations: {
    changeHindex(state, payload) {
      // state表示Store中所有数据
      // payload表示组件中传递过来的数据
      if (payload) state.hIndex = payload;
    },
    changeNavIndex(state, payload) {
      state.navIndex = payload;
    },
    changeLanguage(state, payload) {
      state.navList = englishLang.state.navList
      return
      state.language = payload;
      window.localStorage.setItem('changeLanguage', payload)
      if(payload == "cn"){
        state.navList = chineseLang.state.navList
      }else{
        state.navList = englishLang.state.navList
      }
      window.localStorage.setItem('navList', JSON.stringify(state.navList))
    },
    setNavList(state, list){
      state.navList = list
      window.localStorage.setItem('navList', JSON.stringify(state.navList))
    },
  },
  actions: {
    add(context) {
      context.commit("add");
    },
  }
});
export default store;
