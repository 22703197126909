<template>
  <div class="header">

    <div class="header-box">
      <img :src="logo_url[0]" alt="" @click="changeInx('-1')">
      <div class="header-nav">
        <div class="hvr-underline-from-center" v-for="i in navList" :key="i.inx" @click.stop="changeInx(i.inx, i)">{{
          i.text
          }} <span :class="current == i.inx ? 'hvr_in' : 'hvr_out'"></span>
          <div class="header-nav-list" v-show="i.inx != '-1'">
            <h1 class="header-h1" :class="currentT == j.inx ? 'nav_in' : 'nav_out'" v-for="j in i.proList"
              @click.stop="changeNavInx(i.inx, j.inx)" :key="j.inx">{{ j.text }}</h1>
          </div>
        </div>

      </div>
      <div class="header-icon" @click="changeLang()">

        <a-popover title="">
          <template slot="content">

            <p style="margin-bottom:1px">{{ tips}}</p>
          </template>
          <!-- {{ $store.state.language=='cn' ?'en':'cn'}} -->
          cn
        </a-popover>


      </div>
    </div>
    <div style="display: none;flex-direction: row;align-items: flex-start;justify-content: center;"
      class="mobileHeaderBox">
      <img :src="logo_url[0]" alt="" @click="changeInx('-1')">
      <div class="header-icon" @click="changeLang()">cn</div>
      <div style="display: flex;flex-direction: column;align-items: flex-end;" class="menuBox">
        <a-icon @click="toggleCollapsed" style="color: #fff; font-size: 32px;"
          :type="!collapsed ? 'menu-unfold' : 'menu-fold'" />
        <a-menu v-show="showMenu" :inlineIndent="0" mode="inline" theme="light" :inline-collapsed="collapsed"
          class="menuInner" @openChange="onOpenChange" :open-keys="openKeys" :selectedKeys="selectedKeys"
          @select="selectMenu">
          <a-sub-menu v-for="i in navList" :key="`${i.inx}_${i.text}`" @titleClick="titleClick(i, i.proList)"
            :class="{ noChildren: !i.proList }">
            <span slot="title"><span>{{ i.text }}</span></span>
            <a-menu-item v-for="j in i.proList" :key="`${i.text}_${j.inx}_${j.text}`"
              @click="changeNavInx(i.inx, j.inx)">
              {{ j.text }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
    </div>

  </div>
</template>

<script>
import logo_url from './img/logo.png'
import diqiu from './img/diqiu.png'
import caidan from './img/caidan.png'
import fdj from './img/fdj.png'
import './css/index.less'
import axios from 'axios';
import { getAddress } from '../../api/index'
export default {
    data() {
        return {
            rootSubmenuKeys: [],
            openKeys: [],
            selectedKeys:[],
            collapsed: true,
            showMenu: false,
            timer: null,
            tips: 'click to change language',
            logo_url: [logo_url, diqiu, caidan, fdj],
            // navHeight:0,
            current: localStorage.getItem('hIndex'),// 一级菜单下标
            currentT: localStorage.getItem('hIndex1'),// 二级菜单下标
            // navList: [
            //     { inx: '-1', text: 'Home' },
            //     {
            //         inx: '0', text: 'Event', current: '0', name: 'event', proList: [
            //             { inx: '1', text: 'All Events' },
            //             { inx: '2', text: 'Symposium&Workshop' },
            //             { inx: '3', text: 'Lecture&Seminar' },
            //             { inx: '4', text: 'Professional Training' },
            //             { inx: '5', text: 'Science Popularization ' },
            //             { inx: '6', text: 'Adminssions Activities' },
            //             { inx: '7', text: 'Intenal Event' },
            //         ]
            //     },
            //     {
            //         inx: '1', text: 'Programs', current: '0', name: 'programs',
            //         proList: [
            //             { inx: '1', text: 'All programs' },
            //             { inx: '2', text: 'K-12 STEM Education' },
            //             { inx: '3', text: 'Undergraduate' },
            //             { inx: '4', text: 'Postgraduate' },
            //             { inx: '5', text: 'Postdoc' },
            //             { inx: '6', text: 'Vocational Education' },
            //             { inx: '7', text: 'Top Talent Plan' },
            //         ]
            //     },
            //     {
            //         inx: '2',
            //         text: 'Adminssions',
            //         current: '0',
            //         name: 'adminssions',
            //         proList: [
            //             { inx: '1', text: 'All adminssions' },
            //             { inx: '2', text: 'Announcement' },
            //             { inx: '3', text: 'Undergraduate' },
            //             { inx: '4', text: 'Postgraduate' },
            //             { inx: '5', text: 'Postdoc' },
            //             { inx: '6', text: 'Teaching Faculty' },
            //             { inx: '7', text: 'Research' },
            //             { inx: '8', text: 'Scholarships & Benefits' },
            //             { inx: '9', text: 'Top Talent Plan' },
            //         ]
            //     },
            //     {
            //         inx: '3',
            //         text: 'BGI Life',
            //         current: '0',
            //         name: 'bgiLife',
            //         proList: [
            //             { inx: '1', text: 'Student Association' },
            //             { inx: '2', text: 'Student Actvities' },
            //             { inx: '3', text: 'Living Environment' },
            //             { inx: '4', text: 'Working Environment' },
            //             { inx: '5', text: 'Career Development' },
            //             { inx: '6', text: 'League Affairs' },
            //             { inx: '7', text: 'xueshenghui' },

            //         ]
            //     },
            //     {
            //         inx: '4',
            //         text: 'News',
            //         current: '0',
            //         name: 'news',
            //         proList: [
            //             { inx: '1', text: 'News' },
            //             { inx: '2', text: 'Announcements' },



            //         ]
            //     },
            //     {
            //         inx: '5',
            //         text: 'About',
            //         current: '0',
            //         name: 'about',
            //         proList: [
            //             { inx: '1', text: 'Core Philosophy' },
            //             { inx: '2', text: 'Organizational Structure' },
            //             { inx: '3', text: 'Alumni' },
            //             { inx: '4', text: 'Testimonial' },
            //             { inx: '5', text: 'Contact Us' },
            //             { inx: '6', text: 'Join Us' },
            //         ]
            //     },
            //     { //在a标签后面加地址控制link标签跳转
            //         inx: '6',
            //         text: 'Links',
            //         current: '0',
            //         name: 'Links',
            //         proList: [
            //             { inx: '1', text: 'BGI Group', url: 'https://www.genomics.cn/' },
            //             { inx: '2', text: 'BGI Genomics',url: 'https://www.bgi.com/global' },
            //             { inx: '3', text: 'MGI',url: 'https://en.mgitech.cn/' },
            //             { inx: '4', text: 'China National Genebank',url: 'https://db.cngb.org/' },
            //             { inx: '5', text: 'Gigasience',url: 'https://academic.oup.com/gigascience' },
            //             { inx: '6', text: 'ICG',url: 'https://www.icg.bio/' },
            //         ]
            //     },
            //     { inx: '7', text: 'Online Learning',url: 'https://en.x-omics.org/'},
            // ]
            navList: []
        }
    },
    watch:{
      '$store.state.navIndex': {
            handler(newVal, oldVal) {
                console.log(newVal,'-----------');
                if(newVal) this.currentT = newVal
            },
            deep: true,
        },
      '$store.state.hIndex': {
            handler(newVal, oldVal) {
                console.log(newVal,'-----------');
                if (newVal) this.current = newVal
            },
            deep: true,
        }
    },
    created() {
      this.rootSubmenuKeys = []
      const _navList = this.$store.getters.getNavList
      _navList.forEach(item=>{
        item.key = `${item.inx}_${item.text}`
        this.rootSubmenuKeys.push(item.key)
        if(item.proList){
          item.proList.forEach(i=>{
            i.key = `${i.inx}_${i.text}`
          })
        }
      })
      this.navList = _navList
      console.log(_navList)
      // this.getIp()
        // localStorage.setItem('hIndex', '-1')
    },

    mounted() {
        this.tips = window.localStorage.getItem('changeLanguage') == 'cn' ? ' 点击切换语言'  : 'Chinese'
        //根据自己需要来监听对应的key
        
        // window.addEventListener("setItemEvent", (e) => {
        //     //e.key : 是值发生变化的key
        //     //e.newValue : 是可以对应的新值
        //     if (e.key === "hIndex") {
        //         this.current = e.newValue;
        //         // this.current = localStorage.getItem('hIndex')
        //         console.log(e.newValue, 'hIndex1');
        //     }
        //     if (e.key === "hIndex1") {
        //         this.currentT = e.newValue;
        //         // this.current = localStorage.getItem('hIndex')
        //         // console.log( e.newValue, 'hIndex112');
        //     }
          
        // })

    },


    // 移除监听
    destroyed() {
        window.addEventListener("removeItemEvent", (e) => { });
    },
    // watch: {
    //     current: {
    //         handler(newVal, oldVal) {
    //             console.log(newVal, oldVal, 'newVal,oldVal');
    //             this.current = newVal
    //         },
    //         deep: true
    //     }
    // },
    methods: {
        selectMenu({ item, key, selectedKeys }){
          this.selectedKeys = selectedKeys
        },
        titleClick(i, hasProList){
          if (!hasProList){
            this.changeInx(i.inx, i)
          }
        },
        onOpenChange(openKeys) {
          const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
          if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.openKeys = openKeys;
          } else {
            this.openKeys = latestOpenKey ? [latestOpenKey] : [];
          }
        },
        toggleCollapsed() {
          this.collapsed = !this.collapsed;
          if (!this.collapsed){
            this.showMenu = true
          }else{
            if (this.timer){
              clearTimeout(this.timer)
              this.timer = null
            }
            this.timer = setTimeout(() => {
              this.showMenu = false
              console.log('收起')
            }, 300);
          } 
          if (this.collapsed) this.openKeys = []
        },
        resetMobileHeader(){
          this.collapsed = true
          this.showMenu = false
          this.openKeys = []
          this.selectedKeys = []
        },
        changeLang(){
          window.open("http://www.bgi-college.cn/cnNew/index.aspx","_blank");
          // const langText = localStorage.getItem('changeLanguage') == 'cn' ? 'en' : 'cn'
          const langText = 'en'
          // window.localStorage.setItem('changeLanguage', langText)
          this.$store.commit('changeLanguage',langText)
          this.navList = this.$store.getters.getNavList
          this.tips = window.localStorage.getItem('changeLanguage') == 'cn' ? '点击切换语言' : 'Click to switch languages'
       
          location.reload();
          document.title= localStorage.getItem('changeLanguage')=='cn'?'华大教育中心':'BGI-College'
        },
        changeInx(e,item) {
            if (item && item.url){
              window.open(item.url)
              return
            }

            if (e == '6') {
              return
            } else {
              this.current = e
              console.log(item, 'e');
              localStorage.setItem('hIndex', e)
              localStorage.setItem('hIndex1', '1')
              if (e == '-1') {
                this.$router.push({ name: 'home' });
              } else {
                this.$router.push({ name: this.navList[e * 1 + 1].name });
              }
            }
            this.resetMobileHeader()
        },

        getIp() {
            axios.get('https://api.ipify.org?format=json')
                .then(response => {
                    // this.ipAddress = response.data.ip;
                    const localIp = window.localStorage.getItem('ip') 
                    if (localIp && localIp == response.data.ip){
                      const localNavList = window.localStorage.getItem('navList')
                      const changeLanguage = window.localStorage.getItem('changeLanguage')
                      this.$store.commit('changeLanguage', changeLanguage)
                      this.$store.commit('setNavList', JSON.parse(localNavList))
                      this.navList = this.$store.getters.getNavList
                    }else{
                      console.log(response.data.ip, 'ip');
                      window.localStorage.setItem('ip', response.data.ip)
                      getAddress({ ip: response.data.ip }).then(res => {
                        // console.log(res, 'res');
                        if (res.code == "success") {
                          if (res.Result.country == '中国') {
                            this.$store.commit('changeLanguage', 'cn')
                          } else {
                            this.$store.commit('changeLanguage', 'en')
                          }
                          this.navList = this.$store.getters.getNavList
                        }
                      })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changeNavInx(i, j) {
            console.log(i, j, 'ij');
            if (i == 6) {
                console.log(this.navList[i*1+1],'tiao');
                const openItem = this.navList[i*1+1].proList.filter(item=>{
                  return item.inx == j
                })[0]
                window.open(openItem.url,'_blank')
                return
            }
            this.changeInx(i)
            this.navList.forEach(el => {
                if (el.inx == i) {
                    el.proList.forEach(en => {
                        if (en.inx == j) {
                            el.current = j;
                            if (j) localStorage.setItem('hIndex1', j)
                            console.log(this.navList);
                            console.log(el.name,en.inx,'aaa========');
                            this.$router.push({ name: el.name, query: { id: en.inx } });
                            this.currentT=en.inx 
                            if (el.name == 'event') {
                             
                            }
                        }
                    })
                }
            });
            this.resetMobileHeader()
        }
    }
}
</script>