import { req, reqImg } from './axiosFun';

//获取当前登录地址
export const getAddress = (params) => { return req("post", "/api/sys/common/ipCountry", params) }; 

//获取首页活动列表
export const indexEvents = (params) => { return req("post", "/api/customer/homepage/last9Event", params) }; 

//获取首页新闻列表
export const indexNews = (params) => { return req("post", "/api/customer/homepage/last5New", params) }; 

//获取活动页面列表
export const getEvents = (params) => { return req("post", "/api/customer/event/eventByCls", params) }; 

 //获取活动页面详情
export const getEventsDetail = (params) => { return req("post", "/api/customer/event/eventDetail", params) };

//获取新闻页面列表
export const getNews = (params) => { return req("post", "/api/customer/new/pagelist", params) }; 

//获取新闻页面详情
export const getNewsDel = (params) => { return req("post", "/api/customer/new/newDetail", params) }; 

//获取招生页面列表
export const getAdmission = (params) => { return req("post", "/api/customer/admission/pagelist", params) };

//获取招生页面详情
export const getAdmissionDel = (params) => { return req("post", "/api/customer/admission/detail", params) }; 

//获取感言页面列表
export const getAboutList = (params) => { return req("post", "/api/customer/aboutUs/pagelist", params) }; 

//获取感言页面详情
export const getAboutDel = (params) => { return req("post", "/api/customer/aboutUs/detail", params) }; 

// 获取师资列表
export const getTeacherList = (params) => { return req("post", "/api/customer/mentor/pagelist", params) }; 

// 获取师资详情
export const getTeacherDel = (params) => { return req("post", "/api/customer/mentor/detail", params) }; 
// 判断是国内国外
export const requestIpCountry = (params) => { return req("post", "/api/sys/common/requestIpCountry", params) };
