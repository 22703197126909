export const childProInfo = {
  event: {
    en: {
      proTitle: 'Featured Events',
      proMore: 'Dedicated to Cultivating Innovative  Talent in the Field of Life Science',
    },
    cn: {
      proTitle: '近期活动',
      proMore: '筑起沟通桥梁，共话领域创新',
    },
  },
  programs: {
    en: {
      proTitle: 'Programs',
      proMore: 'Dedicated to Cultivating Innovative  Talent in the Field of Life Science',
    },
    cn: {
      proTitle: '人才培养',
      proMore: '致力于培养生命科学领域的创新拔尖人才',
    },
  },
  adminssions: {
    en: {
      proTitle: 'Admssions',
      proMore: 'Dedicated to Cultivating Innovative  Talent in the Field of Life Science',
    },
    cn: {
      proTitle: '本科生招生',
      proMore: '本科创新班项目和客座学生项目现向全球本科生开放',
    },
  },
  bgiLife: {
    en: {
      proTitle: 'BGI Life',
      proMore: 'Dedicated to Cultivating Innovative  Talent in the Field of Life Science',
    },
    cn: {
      proTitle: '工作环境',
      proMore: '不仅仅是工作场所，更是创造力和进步的中心',
    },
  },
  news: {
    en: {
      proTitle: 'News & Announcements',
      proMore: 'Dedicated to Cultivating Innovative  Talent in the Field of Life Science',
    },
    cn: {
      proTitle: '新闻公告',
      proMore: '关注华大人才培养项目的最新动态',
    },
  },
  about: {
    en: {
      proTitle: 'About',
      proMore: 'Dedicated to Cultivating Innovative  Talent in the Field of Life Science',
    },
    cn: {
      proTitle: '关于我们',
      proMore: '以项目带学科、带人才、带发展',
    },
  },
}