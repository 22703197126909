<template>
    <div class="foot">
        <div class="foot-content">
            <!-- Logo and contact information -->
            <div class="foot-logo">
                <img :src="footLogo" alt="">
                <p>BGI College was established in 2011 and is one of the first batch of specialty colleges approved by the
                    Shenzhen Municipal Government.</p>
                <br>
                <p><a href="https://ditu.amap.com/search?id=B0JUVR78K7&city=440308&geoobj=113.832269%7C22.335382%7C114.487488%7C22.771077&query_type=IDQ&query=%E5%8D%8E%E5%A4%A7%E6%97%B6%E7%A9%BA%E4%B8%AD%E5%BF%83&zoom=11.57" target="_blank"> Address:BGI Center, No.9 Yunhua Road, Yantian District, Shenzhen City</a></p>
                <p>Phone: 0755-36352047</p>
                <p>Email: bgi-college@genomics.cn</p>

            </div>

            <!-- Telephone list -->
            <div class="foot-content-tel" v-for="(i, n) in footList" :key="n">
                <h1>{{ i.h1 }}</h1>
                <p v-for="(j, l) in i.list" :key="l" @click="goto(j)">{{ j.text }}</p>

            </div>


            <!-- Logos -->
            <div class="foot-logos">
                <div class="logo-container">
                    <img class="logo-transition" :src="foot1Src" alt="" @mouseover="changeImage($event, foot1Src, foot11)"
                        @mouseout="changeImage($event, foot11, foot1Src)">
                    <!-- 将p标签移出.hover-box -->
                    <div class="hover-box">
                        <img class="hover-image" src="@/assets/logos/wx.png" alt="Hover Image">
                         <!-- p标签现在在.hover-box内部，垂直堆叠 <p>Wechat Offical Account</p><p>Douyin Offical Account</P>-->
                    </div>
                </div>
                <div class="logo-container">
                    <img class="logo-transition" :src="foot2Src" alt="" @mouseover="changeImage($event, foot2Src, foot21)"
                        @mouseout="changeImage($event, foot21, foot2Src)">
                    <div class="hover-box">
                        <img class="hover-image" src="@/assets/logos/dy.png" alt="Hover Image">
                        

                    </div>
                </div>
                <div class="logo-container">
                    <img class="logo-transition" :src="foot3Src" alt="" @mouseover="changeImage($event, foot3Src, foot31)"
                        @mouseout="changeImage($event, foot31, foot3Src)">
                    <div class="hover-box">
                        <img class="hover-image" src="@/assets/logos/erweima1.png" alt="Hover Image">
                        
                    </div>
                </div>
                <div class="logo-container">
                    <img class="logo-transition" :src="foot4Src" alt="" @mouseover="changeImage($event, foot4Src, foot41)"
                        @mouseout="changeImage($event, foot41, foot4Src)">
                    <div class="hover-box">
                        <img class="hover-image" src="@/assets/logos/xhs.png" alt="Hover Image">
                        
                    </div>
                </div>
            </div>
            <div class="foot-content-img">


            </div>
            <!-- Footer copyright -->
            <div class="foot-p">
                All rights reserved © BGI college
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            footLogo: require('@/assets/logos/foot6.png'),
            foot1: require('@/assets/logos/foot1.png'),
            foot2: require('@/assets/logos/foot2.png'),
            foot3: require('@/assets/logos/foot3.png'),
            foot4: require('@/assets/logos/foot4.png'),
            foot5: require('@/assets/logos/foot5.png'),
            foot11: require('@/assets/logos/foot11.png'), // 需要的图片资源
            foot21: require('@/assets/logos/foot21.png'),
            foot31: require('@/assets/logos/foot31.png'),
            foot41: require('@/assets/logos/foot41.png'),
            url: 'https://ditu.amap.com/place/B0JUVR78K7">',

            footList: [
                {
                    h1: 'Sitemaps',
                    p1: 'Events',
                    p2: 'Programs',
                    p3: 'Adminssions',
                    p4: 'BGI Life',
                    p5: 'News',
                    p6: 'About',
                    list: [{ text: 'Events', link: 'event' ,inx:'0'},
                    { text: 'Programs', link: 'programs',inx:'1' },
                    { text: 'Adminssions', link: 'adminssions',inx:'2' },
                    { text: 'BGI Life', link: 'bgiLife',inx:'3' }]
                },
                {
                    h1: 'Related Link',
                    p1: 'BGI Groups',
                    p2: 'MGI',
                    p3: 'Recruitment Network',
                    p4: 'Online Learning',
                    list: [
                        { text: 'BGI Groups', link: 'https://en.genomics.cn/' },
                        { text: 'MGI', link: 'https://en.mgitech.cn/' },
                        { text: 'Recruitment Network', link: 'https://genomics.zhiye.com/' },
                        { text: 'Online Learning', link: 'https://en.x-omics.org/' }
                    ]
                },
            ],
        };
    },
    computed: {
        foot1Src: {
            get() { return this.foot1; },
            set(value) { this.foot1 = value; }
        },
        foot2Src: {
            get() { return this.foot2; },
            set(value) { this.foot2 = value; }
        },
        foot4Src: {
            get() { return this.foot3; },
            set(value) { this.foot3 = value; }
        },
        foot3Src: {
            get() { return this.foot4; },
            set(value) { this.foot4 = value; }
        },
    },
    methods: {
        changeImage(event, oldSrc, newSrc) {
            event.target.src = newSrc;
        },
        goto(e) {
            if(e.inx){
                console.log(e, '123');
                localStorage.setItem('hIndex', e.inx)
                this.$router.push({
                    name: e.link,
                    query: {

                    }
                })
            }else{
                window.open(e.link)
            }
        }

    },
};
</script>

<style scoped lang="less">
.logo-container {
    position: relative;
    /* 设置相对定位 */
    display: inline-block;
    /* 使容器成为行内块级元素 */
    margin: 0 20px;
    /* 根据需要调整外边距 */
}

.hover-box {
    position: absolute;
    top: -100%; /* 保持在.logo-container的正上方 */
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    /* 增加高度以适应垂直堆叠的内容 */
    height: 130px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease, top 0.3s ease;
    z-index: 2;
    display: flex;
    flex-direction: column; /* 修改为垂直堆叠 */
    align-items: center; /* 垂直居中 */
    justify-content: center;
}

.hover-box img.hover-image {
    min-width: 110px;
    min-height: 110px;
}



.logo-container:hover .hover-box {
    opacity: 1;
}


.foot {
    width: 100%;
    background: #000;
    padding: 20px 0;
    /* 添加一些内边距 */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* 使用列布局 */
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 50px;
    user-select: none;

    .foot-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        /* 允许内容换行 */
        justify-content: center;
        align-items: center;

        .foot-logo {
            width: 100%;
            max-width: 600px;
            /* 最大宽度 */
            // margin-left: 200px;
            text-align: left;
            flex-wrap: nowrap;
            /* 禁止换行 */
            margin-bottom: 50px;


            >img {
                width: auto;
                cursor: pointer;
                height: 60px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                font-weight: 500;

                line-height: 1.5;
                color: #999;


            }

            a {
                color: #999;

            }

            a:hover {
                color: rgb(0, 172, 210)
            }


            .foot-content-tel {
                width: 100%;
                max-width: 300px;
                /* 最大宽度 */
                margin-bottom: 20px;
                text-align: center;

            }

            .foot-content-tel h1 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            .foot-content-tel p {
                font-size: 22px;
                color: rgba(255, 255, 255, 0.7);
                transition: border-bottom 0.5s ease;
                border-bottom: 1px solid transparent;
            }

            .foot-content-tel p:hover {
                text-decoration: none;
                border-bottom: 1px solid #fff;
                cursor: pointer;
                color: #999;
            }
        }

        .foot-logos {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            // margin-right: 200px;
            align-items: center;

            img {
                width: auto;
                cursor: pointer;
                height: 40px;
                margin: 0 20px;
                margin-bottom: 40px;
                margin: 40px;
                // cursor: pointer;
            }
        }

        .logo-transition {
            transition: opacity 1s ease;
            /* 设置过渡效果为0.5秒的淡入淡出 */
            opacity: 0.7;
            /* 设置初始透明度为完全可见 */
        }

        .logo-transition:hover {
            opacity: 1;
            /* 鼠标悬停时设置透明度为0.5，即完全透明 */
        }

        .foot-content-img {
            display: flex;
            /* 使用Flexbox布局 */
            justify-content: center;
            /* 图片水平居中 */
            align-items: center;
            /* 图片垂直居中 */
            width: 100%;

            /* 确保容器宽度为100% */
            .foot-content-img img {
                width: auto;
                /* 图片宽度自动 */
                height: auto;
                /* 高度自动，保持原始宽高比 */
                /* 如果图片高度过大，可以添加max-height属性 */
                /* max-height: 100px; */
                /* 根据需要调整 */
                margin: 5px;
                /* 添加适当的外边距，避免图片之间重叠 */
            }
        }
    }

    .foot-content-tel {
        margin: 0 auto;

        h1 {
            font-size: 24px;
            color: #fff;
        }

        p {
            font-size: 22px;
            color: rgba(#666);
            transition: border-bottom 0.5s ease;
            /* 添加过渡效果 */
            border-bottom: 1px solid transparent;
            /* 默认下划线为透明 */
        }

        p:hover {
            text-decoration: none;
            /* 移除默认的下划线 */
            border-bottom: 1px solid #fff;
            cursor: pointer;
            color: #999;
        }
    }
}

.foot-p {
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    // position: static; /* 保持静态定位 */
    margin-bottom: 20px;
    margin-top: 20px;
    color: #999;

}


@media (min-width: 1345px) {
    .foot-logo {
        margin-left: 200px;
    }




}

/* 媒体查询 - 针对小屏幕设备 */
@media (min-width: 545px) and (max-width: 1345px) {
    .foot-content {
        flex-direction: column;
        align-items: center;
        /* 当窗口小于768px时，元素靠左对齐 */
        justify-content: center;
        /* 图标靠左对齐 */
    }

    .foot-content-tel {
        max-width: 100%;
        text-align: center;
        /* 当窗口小于768px时，文本靠左对齐 */
        margin-left: 0px;
    }

    .foot-logos {
        justify-content: center;
        /* 图标靠左对齐 */
    }
}



@media (max-width: 544px) {
    .foot-content {
        justify-content: center;
        /* 元素靠左对齐 */
    }

    .foot-content-tel {
        text-align: center;
        /* 文本靠左对齐 */
        margin-bottom: 20px;
    }

    .foot-logos {
        justify-content: center;
        /* 图标靠左对齐 */
        margin-left: 200px;
    }

    .foot-p {
        margin-left: 0;
        /* 移除自动填充左侧空间 */
    }
    .hover-box {
        z-index: -999;
    }
}</style>

