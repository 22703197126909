import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from '@/router';
import less from 'less'
// import animated from 'animate.css' // npm install animate.css --save安装，在引入
import store from '@/store'
import tool from "./utils/tool";
import '@/assets/fonts/font.less';
import { commonEvt } from "@/minixs/commonMethonds"
Vue.mixin(commonEvt);

Vue.use(tool);
// Vue.use(animated)
Vue.config.productionTip = false
Vue.use(Antd);
Vue.use(less);
new Vue({
  store,
  render: h => h(App),
  router:router
}).$mount('#app')

