import axios from "axios";
// import store from "../vuex/store";
import router from "@/router";
// 登录请求方法

// 通用公用方法
const req = (method, url, params) => {
  console.log(method,url,params);
  const reqInfo = { method, url, params };
  window.localStorage.setItem("reqInfo", JSON.stringify(reqInfo));
  return axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      // token: localStorage.getItem("logintoken"),
    },
    data: params,
    traditional: true,
  
  }).then((res) => {
    console.log(res.data, "resqe");
  
    return res.data;
  });
};
// 图片上传请求
const reqImg = (method, url, params) => {
    return axios({
      method: method,
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("logintoken"),
      },
      data: params,
      traditional: true,
     
    }).then((res) => {
    
      return res.data;
    });
  };
export {  req,reqImg };
