import { englishHomeInfo , chineseHomeInfo } from "./homePageTextInfo";
import { childProInfo } from "./childProInfo";
import { req, reqImg } from '@/api/axiosFun';

export const langChangeMinix = {
  data() {
    return {
      proTitle:'',
      homePageInfo: { ...englishHomeInfo },
      homeInfoList: {
        en: englishHomeInfo,
        cn: chineseHomeInfo
      },
      proInfo:{
        proTitle:'',
        proMore:''
      },
      langeType:'en',
    }
  },
  watch: {
    '$store.state.language': {
      handler(newVal, oldVal) {
        this.setProList()
        this.repeatAxios()
      },
      // immediate:true,
      deep: true
    },
    '$route.query': {
        handler(newVal, oldVal) {
          this.change(newVal.id)
        },
        deep: true,
    },
  },
  created() {
    // window.addEventListener("load", (event) => {
    //   if (event.type === "load") {
    //     // 页面通过刷新加载
    //     setTimeout(() => {
    //       this.setProList()
    //     }, 500)
    //   }
    //   return
    // });
    this.setProList()
  },
  methods: {
    setProList() {
      if (window.localStorage.getItem('hIndex') != -1 && this.$router.currentRoute.name != 'home') {
        const pageName = this.$router.currentRoute.name
        const changeLanguageName = this.$store.state.language
        this.langeType = changeLanguageName
        console.log(pageName,changeLanguageName,'changeLanguageName');
        this.proInfo.proTitle = childProInfo[pageName][changeLanguageName]?.proTitle ?? ''
        this.proInfo.proMore = childProInfo[pageName][changeLanguageName]?.proMore ?? ''
      }
      if (this.$store.state.navList) {
        const filterProList = this.$store.state.navList.filter(item => {
          return item.inx == window.localStorage.getItem('hIndex')
        })
        if (filterProList[0]) {
          this.proTitle = filterProList[0].text
          this.proList = filterProList[0].proList
          this.setHomePageInfo()
        }
      }
      this.scrollToTop()
    },
    setHomePageInfo() {
      if (window.localStorage.getItem('hIndex') == -1){
        // this.homePageInfo = this.homeInfoList[this.$store.state.language]
        this.homePageInfo = this.homeInfoList['en']
        console.log(this.homePageInfo);
      }
    },
    repeatAxios(){
      const reqInfo = JSON.parse(window.localStorage.getItem("reqInfo"));
      console.log(reqInfo);
      if (reqInfo.params && reqInfo.params.ver) {
        reqInfo.params.ver = reqInfo.params.ver == 'cn' ? 'en': 'cn'
      }
      req(reqInfo.method, reqInfo.url, reqInfo.params)
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
}

export const englishLang = {
  namespaced: true,
  state: {
    name: 'englishLang',
    type: 'EnglishLang',
    navList: [
      { inx: '-1', text: 'Home', name: 'home' },
      {
        inx: '0', text: 'Events', current: '0', name: 'event', proList: [
          { inx: '1', text: 'All Events' },
          { inx: '2', text: 'Symposia & Workshops' },
          { inx: '3', text: 'Lectures & Seminars' },
          { inx: '4', text: 'Professional Training' },
          { inx: '5', text: 'Science Popularization ' },
          { inx: '6', text: 'Admissions Activities' },
          { inx: '7', text: 'Internal Events' },
        ]
      },
      {
        inx: '1', text: 'Programs', current: '0', name: 'programs',
        proList: [
          { inx: '1', text: 'All programs' },
          { inx: '2', text: 'K-12 STEM Education' },
          { inx: '3', text: 'Undergraduate' },
          { inx: '4', text: 'Postgraduate' },
          { inx: '5', text: 'Postdoc' },
          { inx: '6', text: 'Vocational Education' },
          { inx: '7', text: 'Top Talent Plan' },
        ]
      },
      {
        inx: '2',
        text: 'Admissions',
        current: '0',
        name: 'adminssions',
        proList: [
          { inx: '1', text: 'All admissions' },
          { inx: '2', text: 'Announcement' },
          { inx: '3', text: 'Undergraduate' },
          { inx: '4', text: 'Postgraduate' },
          { inx: '5', text: 'Postdoc' },
          { inx: '6', text: 'Teaching Faculty' },
          { inx: '7', text: 'Research' },
          { inx: '8', text: 'Scholarships & Benefits' },
          { inx: '9', text: 'Top Talent Plan' },
        ]
      },
      {
        inx: '3',
        text: 'BGI Life',
        current: '0',
        name: 'bgiLife',
        proList: [
          { inx: '1', text: 'Student Association' },
          { inx: '2', text: 'Student Actvities' },
          { inx: '3', text: 'Living Environment' },
          { inx: '4', text: 'Working Environment' },
          { inx: '5', text: 'Career Development' },
          // { inx: '6', text: 'League Affairs' },
          { inx: '7', text: 'Student Union' },

        ]
      },
      {
        inx: '4',
        text: 'News',
        current: '0',
        name: 'news',
        proList: [
          { inx: '1', text: 'News' },
          { inx: '2', text: 'Announcements' },



        ]
      },
      {
        inx: '5',
        text: 'About',
        current: '0',
        name: 'about',
        proList: [
          { inx: '1', text: 'About' },
          { inx: '2', text: 'Core Philosophy' },
          { inx: '3', text: 'Organizational Structure' },
          { inx: '4', text: 'Alumni' },
          { inx: '5', text: 'Testimonial' },
          { inx: '6', text: 'Contact Us' },
          { inx: '7', text: 'Join Us' },
        ]
      },
      { //在a标签后面加地址控制link标签跳转
        inx: '6',
        text: 'Links',
        current: '0',
        name: 'Links',
        proList: [
          { inx: '1', text: 'BGI Group', url: 'https://www.genomics.cn/index.html' },
          { inx: '2', text: 'BGI Genomics', url: 'https://www.bgi.com/' },
          { inx: '3', text: 'MGI', url: 'https://www.mgitech.cn/' },
          { inx: '4', text: 'China National Genebank', url: 'https://db.cngb.org/' },
          { inx: '5', text: 'Giga Science Press', url: 'https://academic.oup.com/gigascience' },
          { inx: '6', text: 'ICG', url: 'https://www.icg.bio/' },
        ]
      },
      { inx: '7', text: 'Online Learning', url: 'https://en.x-omics.org/' },
    ]
  }
}
export const chineseLang = {
  namespaced: true,
  state: {
    name: 'chineseLang',
    type: 'ChineseLang',
    navList: [
      { inx: '-1', text: '首页', name: 'home' },
      {
        inx: '0', text: '活动', current: '0', name: 'event', proList: [
          { inx: '1', text: '全部活动' },
          { inx: '2', text: '学术会议 & 工作坊' },
          { inx: '3', text: '讲座 & 研讨会' },
          { inx: '4', text: '专业培训' },
          { inx: '5', text: '科普' },
          { inx: '6', text: '招生活动' },
          { inx: '7', text: '内部活动' },
        ]
      },
      {
        inx: '1', text: '人才培养', current: '0', name: 'programs',
        proList: [
          { inx: '1', text: '全部人才培养' },
          { inx: '2', text: '中小学科学教育' },
          { inx: '3', text: '本科生' },
          { inx: '4', text: '研究生' },
          { inx: '5', text: '博士后' },
          { inx: '6', text: '职业教育' },
          { inx: '7', text: '拔尖计划' },
        ]
      },
      {
        inx: '2',
        text: '招生',
        current: '0',
        name: 'adminssions',
        proList: [
          { inx: '1', text: '全部招生' },
          { inx: '2', text: '公示' },
          { inx: '3', text: '本科生' },
          { inx: '4', text: '研究生' },
          { inx: '5', text: '博士后' },
          { inx: '6', text: '师资队伍' },
          { inx: '7', text: '科学研究' },
          { inx: '8', text: '奖助体系' },
          { inx: '9', text: '拔尖计划' },
        ]
      },
      {
        inx: '3',
        text: '学生时空',
        current: '0',
        name: 'bgiLife',
        proList: [
          { inx: '1', text: '学生组织' },
          { inx: '2', text: '学生活动' },
          { inx: '3', text: '生活环境' },
          { inx: '4', text: '工作环境' },
          { inx: '5', text: '职业发展' },
          { inx: '6', text: '党团事务' },
          { inx: '7', text: '学生会' },

        ]
      },
      {
        inx: '4',
        text: '新闻公告',
        current: '0',
        name: 'news',
        proList: [
          { inx: '1', text: '新闻' },
          { inx: '2', text: '公告' },
        ]
      },
      {
        inx: '5',
        text: '关于我们',
        current: '0',
        name: 'about',
        proList: [
          { inx: '1', text: '中心理念' },
          { inx: '2', text: '组织架构' },
          { inx: '3', text: '校友' },
          { inx: '4', text: '感言' },
          { inx: '5', text: '联系我们' },
          { inx: '6', text: '加入我们' },
        ]
      },
      {
        inx: '6',
        text: '更多链接',
        current: '0',
        name: 'Links',
        proList: [
          { inx: '1', text: 'BGI Group', url: 'https://www.genomics.cn/index.html' },
          { inx: '2', text: 'BGI Genomics', url: 'https://www.bgi.com/' },
          { inx: '3', text: 'MGI', url: 'https://www.mgitech.cn/' },
          { inx: '4', text: 'China National Genebank', url: 'https://db.cngb.org/' },
          { inx: '5', text: 'Giga Science Press', url: 'https://academic.oup.com/gigascience' },
          { inx: '6', text: 'ICG', url: 'https://www.icg.bio/' },
        ]
      },
      { inx: '7', text: '在线学习', url: 'https://en.x-omics.org/' },
    ]
  }
}